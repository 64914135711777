import getConfig from 'next/config';

export const getImageUrl = (url: string, size: string) => {
  const { publicRuntimeConfig } = getConfig();

  if (url.includes('assets/')) {
    return url;
  }

  return `${publicRuntimeConfig.PUBLIC_IMAGE_HOST}${url}`.replace('/large/', `/hera_${size}/`);
};

export default getImageUrl;
