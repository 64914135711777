import stripHtml from '@src/utils/strip-html';
import {decode} from 'html-entities';
import { Paragraph } from '@src/interfaces';

const articlePlainText = (title: string, paragraphs: Paragraph[], teaserText?: string|null) => {
  const plainTextContents: string[] = [];

  plainTextContents.push(`${title}.`)

  if (teaserText) {
    plainTextContents.push(`${teaserText}.`)
  }
  paragraphs.map((paragraph: Paragraph) => {
    if (paragraph.type === 'text') {
      plainTextContents.push(stripHtml(paragraph.data.html.replace(/<\/(li|h1|h2|h4|h5|h6)>/ig, ".")))
    }
    return paragraph;
  });

  const plainText = decode(plainTextContents.join(' '))
  plainText.replace(  ' .', '.')
  plainText.replace(' ,', ',')
  plainText.replace('..', '.')
  plainText.replace('  ', ' ')

  return plainText
}

export default articlePlainText
