import React, { MouseEventHandler, useEffect } from 'react';
import getGlobalCleverpush from '@src/components/Cleverpush/sdk';
import { CleverpushTopic } from '@src/interfaces';
import { trackEvent } from '@src/utils/et';
import {Models} from "@data/et-web-api";

const CleverpushTopicButton = ({ id, name }: CleverpushTopic) => {
  useEffect(() => {
    getGlobalCleverpush().then(sdk => sdk?.initTopicButtons());
  }, []);

  const onClickTopicButton: MouseEventHandler<HTMLElement> = e => {
    const wasSubscribed = (e.target as HTMLElement).classList.contains(
      'cleverpush-topic-button-unsubscribed',
      );
    const eventName: Models.SettingsClick["eventName"] = 'Settings Click';
    const eventPayload: Models.SettingsClick["eventPayload"] = {
      settingsLabel: wasSubscribed ? 'Cleverpush off' : 'Cleverpush on',
    }
    trackEvent(eventName, eventPayload);
    return true;
  };
  return (
    <button
      aria-label={name}
      className="cleverpush-topic-button"
      data-button-text={name}
      data-button-text-subscribed="&nbsp;"
      data-topic={id}
      onClick={onClickTopicButton}
      type="button"
    />
  );
};
export default CleverpushTopicButton;
