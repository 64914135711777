import fetchJson from '@src/utils/api/fetch';
import { User } from '@src/interfaces';
import { NextPageContext } from 'next';

const fetchAuthors = (
  asPath: NextPageContext['asPath'],
): Promise<User[]> => {
  if (!asPath) {
    throw new Error('No appContext.ctx.asPath');
  }
  const uri = new URL(asPath, 'http://localhost');
  const alias = uri.pathname.replace(/\/$/, '');
  const url = `/api/article/p7s1p4/authors?alias=/puls24at${alias}`;
  return fetchJson<User[]>({ url }).then(response => response.body)
    .catch(err => {
      console.error('fetchAuthors', err);
      return [];
  });
};

export default fetchAuthors;
