import React from 'react';
import type { FC } from 'react';
import { User } from '@src/interfaces';
import PersonAvatar from 'src/components/PersonAvatar';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import styles from './style.module.scss';

type PersonTeaserComponentProps = User;

const PersonTeaserComponent: FC<PersonTeaserComponentProps>
  = ({
   name,
   shortDescription,
   userPicture,
   url
 }
) =>
  <div className={styles.personTeaser}>
    <div className={styles.header}>
      <PersonAvatar displayName={name} path={url ? stripUrlPlaceholder(url) : ''} picture={userPicture}/>

      <div className={styles.tags}>
        {url ? <Link as={stripUrlPlaceholder(url)} href='/'>
          <a className={styles.name} title="Zur Autorenseite">
            {name}
          </a>
        </Link> :
        <span className={styles.name}>{name}</span>
        }
        <span className={styles.description}>{shortDescription}</span>
      </div>
    </div>
  </div>


export default PersonTeaserComponent;
