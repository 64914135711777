import type { FC } from 'react';
import type { ArticleComponent, Paragraph } from '@src/interfaces';
import React from 'react';
import ArticleLayout from '@src/components/Layout/ArticleLayout';
import ParagraphComponent from '@src/components/Paragraph/ParagraphComponent';

// TODO: Move layout to page component when fixing routing
const Article: FC<ArticleComponent> = props => (
  <ArticleLayout {...props}>
    {props.paragraphs.map((paragraph: Paragraph, index) => (
      <ParagraphComponent key={index} paragraph={paragraph} />
    ))}
  </ArticleLayout>
)

export default Article;
