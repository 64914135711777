import React from 'react';
import type {FC} from 'react';
import { Image } from '@src/interfaces';
import CustomImage from '@src/components/CustomImage';
import Link from 'next/link';
import styles from './styles.module.scss';

type UserAvatarProps = {
  displayName: string;
  picture?: Image;
  size?: 'small' | 'medium';
  path?: string;
}
const PersonAvatar : FC<UserAvatarProps> = ({displayName, picture, path,  size = 'small'}) => {
  const avatar = <>
    {picture?.url ? (
      <CustomImage className={styles.image}
        {...picture}
        size='xsmall_square'
        title={displayName} />
      )
      : <div className={styles.pictureStub}>
        <span
          className={styles.initials}>{displayName.split(' ').map(t => t.slice(0, 1)).join('')}</span>
      </div>
    }
  </>
  return (
    <div className={size === 'medium' ? styles.containerMedium : styles.container}>
      {path ? <Link as={path} href="/"><a className={styles.link} title={displayName}>{avatar}</a></Link> : null}
      {!path && <a className={styles.link} title={displayName}>{avatar}</a>}
    </div>
  );
}

export default PersonAvatar;
