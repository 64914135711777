import React from 'react';
import type { FC } from 'react';
import type { User } from '@src/interfaces';
import PersonAvatar from 'src/components/PersonAvatar';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import styles from './styles.module.scss';

type UserCompactListProps = {
  users: User[];
  heading?: string;
}

const PersonAvatarList: FC<UserCompactListProps> = ({ users, heading }) => (
  <div className={styles.container}>
    {heading && <h5>{heading}</h5>}
    <div className={styles.items}>
      {users.slice().reverse().map((user, index) =>
        <span key={index} className={styles.item}>
          <PersonAvatar displayName={user.name} path={user?.url && stripUrlPlaceholder(user.url)} picture={user.userPicture}/>
        </span>
      )}
    </div>
  </div>
)

export default PersonAvatarList;
