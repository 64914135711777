import type { FC } from 'react';
import { Breadcrumbs } from '@src/interfaces';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import React from 'react';

const BreadcrumbNavigation: FC<Breadcrumbs> = ({ links }) => (
  <>
    {links.slice(-1).map((item, index) => (
      <Link key={index} as={stripUrlPlaceholder(item.link)} href="/">
        <a className="category">{item.title}</a>
      </Link>
    ))}
  </>
);

export default BreadcrumbNavigation;
