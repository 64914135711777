import React from 'react';
import dynamic from 'next/dynamic';
import ParagraphProps from '@src/components/Paragraph/paragraph-props';
import ScriptWidgetParagraph from '@src/components/Paragraph/ScriptWidgetParagraph';

const ApaSoccerParagraph = dynamic(() => import('@src/components/Paragraph/ApaSoccerParagraph'));
const AudioParagraph = dynamic(() => import('@src/components/Paragraph/AudioParagraph'));
const BannerParagraph = dynamic(() => import('@src/components/Paragraph/BannerParagraph'));
const CleverpushWidgetParagraph = dynamic(
  () => import('@src/components/Paragraph/CleverpushWidgetParagraph'),
);
const CumulusVideoParagraph = dynamic(
  () => import('@src/components/Paragraph/CumulusVideoParagraph'),
);
const GalleryParagraph = dynamic(() => import('@src/components/Paragraph/GalleryParagraph'));
const GlomexParagraph = dynamic(() => import('@src/components/Paragraph/GlomexParagraph'));
const IframeParagraph = dynamic(() => import('@src/components/Paragraph/IframeParagraph'));
const ImageParagraph = dynamic(() => import('@src/components/Paragraph/ImageParagraph'));
const LinkParagraph = dynamic(() => import('@src/components/Paragraph/LinkParagraph'));

const LiveblogParagraph = dynamic(
  () =>
    // eslint-disable-next-line import/no-cycle
    import('@src/components/Paragraph/LiveblogParagraph'),
);
const PodigeeParagraph = dynamic(() => import('@src/components/Paragraph/PodigeeParagraph'));
const QuoteParagraph = dynamic(() => import('@src/components/Paragraph/QuoteParagraph'));
const RiddleParagraph = dynamic(() => import('@src/components/Paragraph/RiddleParagraph'));
const SomtagParagraph = dynamic(() => import('@src/components/Paragraph/SomtagParagraph'));
const TeaserParagraph = dynamic(() => import('@src/components/Paragraph/TeaserParagraph'));
const TextParagraph = dynamic(() => import('@src/components/Paragraph/TextParagraph'));
const TwitterParagraph = dynamic(() => import('@src/components/Paragraph/TwitterParagraph'));
const VideoParagraph = dynamic(() => import('@src/components/Paragraph/VideoParagraph'));
const FacebookParagraph = dynamic(() => import('@src/components/Paragraph/FacebookParagraph'));
const InstagramParagraph = dynamic(() => import('@src/components/Paragraph/InstagramParagraph'));
const TiktokParagraph = dynamic(() => import('@src/components/Paragraph/TiktokParagraph'));
const QualifioParagraph = dynamic(() => import('@src/components/Paragraph/QualifioParagraph'));
const AuthorListParagraph = dynamic(() => import('@src/components/Paragraph/AuthorListParagraph'));
const InfoboxParagraph = dynamic(() => import('@src/components/InfoboxParagraph'));

const ParagraphComponent = ({ paragraph }: ParagraphProps) => {
  switch (paragraph.type) {
    case 'apa_soccer':
      return <ApaSoccerParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'audio':
      return <AudioParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'banner':
      return <BannerParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'cleverpush_widget':
      return <CleverpushWidgetParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'cumulus_video':
      return <CumulusVideoParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'gallery':
      return <GalleryParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'glomex':
      return <GlomexParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'iframe':
      return <IframeParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'image':
      return <ImageParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'link':
      return <LinkParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'liveblog':
      return <LiveblogParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'podigee':
      return <PodigeeParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'quote':
      return <QuoteParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'riddle':
      return <RiddleParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'script_widget':
      return <ScriptWidgetParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'somtag':
      return <SomtagParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'teaser':
      return <TeaserParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'text':
      return <TextParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'twitter':
      return <TwitterParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'video':
      return <VideoParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'facebook':
      return <FacebookParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'instagram':
      return <InstagramParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'tiktok':
      return <TiktokParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'qualifio':
      return <QualifioParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'author_list':
      return <AuthorListParagraph key={paragraph.id} paragraph={paragraph} />;

    case 'infobox':
      return <InfoboxParagraph key={paragraph.id} icon={paragraph.data.icon} text={paragraph.data.text} title={paragraph.data.title} />;

    default:
      console.warn(`Unmapped paragraph type ${paragraph.type}`);
      return null;
  }
};

export default ParagraphComponent;
