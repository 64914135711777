import type { FC } from 'react';
import type { ArticleComponent, User } from '@src/interfaces';
import React, { useEffect, useState, useCallback } from 'react';
import ImageComponent from '@src/components/Image/ImageComponent';
import SocialButtons from '@src/components/SocialButtons/SocialButtons';
import OasisPlayer from '@src/components/OasisPlayer/OasisPlayer';
import Breadcrumb from '@src/components/Breadcrumb/Breadcrumb';
import BreadcrumbNavigation from '@src/components/Breadcrumb/BreadcrumbNavigation';
import CleverpushTopicButton from '@src/components/Cleverpush/TopicButton';
import getFormattedDate from '@src/utils/get-formatted-date';
import PersonTeaserComponent from '@src/components/PersonTeaser';
import fetchAuthors from '@src/utils/api/fetch-authors';
import { useRouter } from 'next/router';
import PersonAvatarList from 'src/components/PersonAvatarList';
import TextToSpeech from '@src/components/TextToSpeech';
import articlePlainText from '@src/utils/article-plain-text';

const ArticleLayout: FC<ArticleComponent> = ({
  title,
  url,
  origin,
  cleverpushTopic,
  teaserMedia,
  teaserText,
  breadcrumbs,
  advertorial,
  timestamp,
  readTime,
  children,
  summary,
  hideSummary,
  paragraphs,
  preTitle
}) => {
  const getBreadcrumbItems = useCallback(() => [
    advertorial ? 'Anzeige' : '',
    timestamp ? getFormattedDate(timestamp) : '',
    readTime ? `Lesedauer\u00A0${readTime}\u00A0min` : '',
  ].filter(Boolean), [advertorial, timestamp, readTime]);
  const [breadcrumbItems, setBreadcrumbItems] = useState<string[]>(getBreadcrumbItems());
  const [articleAuthors, setArticleAuthors] = useState<User[]>(origin?.authors ?? []);
  const [plainText, setPlainText] = useState<string|null>(null)
  const { asPath } = useRouter();

  useEffect(() => {
    fetchAuthors(asPath).then(authors => {
      setArticleAuthors(authors);
    });
  }, [asPath]);

  useEffect(() => {
    const text = articlePlainText(title, paragraphs, teaserText)
    setPlainText(text)
  }, [paragraphs, teaserText, title]);

  useEffect(() => {
    setBreadcrumbItems(getBreadcrumbItems());
  }, [getBreadcrumbItems]);

  let lead;
  if (teaserMedia?.video) {
    lead = (
      <figure className="lead has-video">
        <OasisPlayer
          key={teaserMedia.id}
          image={teaserMedia.image}
          isActive
          video={teaserMedia.video}
        />
      </figure>
    );
  } else if (teaserMedia?.image) {
    lead = (
      <figure className="lead">
        <ImageComponent key={teaserMedia.id} image={teaserMedia.image} priority size="xlarge" />
      </figure>
    );
  }

  return (
    <article className="article" data-io-article-url={url}>
      {lead}

      <div className="article-background">
        <header className="gray-overlay">
          <div className="breadcrumb-navigation">

            <div className="breadcrumb-navigation__widget">
              {!!breadcrumbs?.links.length && !advertorial && (
                <BreadcrumbNavigation links={breadcrumbs.links} />
              )}

              {!!cleverpushTopic && (
                <CleverpushTopicButton id={cleverpushTopic.id} name="Abonnieren" />
              )}
            </div>

          </div>
          {preTitle && <p className="article-pretitle">{preTitle}</p>}
          <h1>{title}</h1>

          {breadcrumbItems.length ? <Breadcrumb breadcrumbItems={breadcrumbItems} /> : null}

          {plainText && <TextToSpeech text={plainText} /> }

        </header>

        <div className="content">
          {!!teaserText && <p className="teaser-text">{teaserText}</p>}

          {children}
        </div>

        {!!summary?.length && !hideSummary && (
        <div className="summary">
          <h5>Zusammenfassung</h5>
          <ul>
            {summary.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
        )}

        <footer className="article-footer">
          <div className="article-origin">
            {(origin?.shortcut || origin?.sources?.length) ? <>
              <span className="title">Quelle:&nbsp;</span>

              <span className="sources">
                {[
                    ...(origin.sources ? origin.sources : []),
                    ...(origin.shortcut ? [origin.shortcut] : []),
                  ].join(' / ')}
              </span>
            </> : null}
            {!!articleAuthors.length && (
              <div className="authors">
                {articleAuthors.length < 3
                  ? articleAuthors.map((author, index) => <PersonTeaserComponent key={index} {...author}/>)
                  : (<PersonAvatarList heading="Autoren:" users={articleAuthors}/>)}
              </div>
            )}
          </div>

          <div className="share-buttons-bottom">
            <SocialButtons label={title} showTitle url={url} />
          </div>
        </footer>
      </div>
    </article>
  );
};
export default ArticleLayout;
