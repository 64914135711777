import type { FC } from 'react';
import React from 'react';

type BreadcrumbProps = {
  breadcrumbItems: string[];
}

const Breadcrumb: FC<BreadcrumbProps> = ({ breadcrumbItems }) => (
  <span className="breadcrumb" suppressHydrationWarning>{breadcrumbItems.join(' · ')}</span>
);

export default Breadcrumb;
