import React from 'react';
import SocialIcons, {SocialStyling} from '@src/components/SocialButtons/SocialIcons';
import ShareButton from '@src/components/SocialButtons/ShareButton';

const SHARE_URLS = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
  twitter: 'https://twitter.com/intent/tweet?via=puls24news&text=',
  bluesky: 'https://bsky.app/intent/compose?text=',
};

type SocialButtonProps = {
  label: string;
  url: string;
  showTitle: boolean;
  styling?: SocialStyling | undefined;
};

const SocialButtons = (props: SocialButtonProps) => (
  <div aria-label="Artikel teilen" className="social-button-group" role="group">
    <SocialIcons baseUrls={SHARE_URLS} {...props} />
    <ShareButton className="" label={props.label} url={props.url} />
  </div>
);

export default SocialButtons;
