import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk'
import classNames from 'classnames';
import SvgImage from '@src/components/SvgImage';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { Models } from '@data/et-web-api';
import { trackEvent } from '@src/utils/et';
import styles from './styles.module.scss'

type TextToSpeechProps = {
  text: string;
  voiceName?: string;
}

const TextToSpeech = ({ text, voiceName = 'en-US-NovaMultilingualNeural' }: TextToSpeechProps) => {
  const router = useRouter()

  const [synthesizer, setSynthesizer] = useState<sdk.SpeechSynthesizer|null>(null);
  const [synthesisComplete, setSynthesisComplete] = useState(false)
  // Not started = 0, Playing = 1, Paused = 2.
  const [playingStatus, setPlayingStatus] = useState(0)
  const [player, setPlayer] = useState<sdk.SpeakerAudioDestination|null>(null)

  const { publicRuntimeConfig } = getConfig();
  const key = publicRuntimeConfig.AZURE_CS_KEY;

  const playBtn = useRef<HTMLAnchorElement>(null)

  const initSDK = () => {
    if (!key) {
      return;
    }
    const apiUrl = new URL('https://swedencentral.tts.speech.microsoft.com/cognitiveservices/websocket/v1');

    try {
      const speechConfig = sdk.SpeechConfig.fromEndpoint(apiUrl, key);
      // The language of the voice that speaks.
      speechConfig.speechSynthesisVoiceName = voiceName;

      const playerObj = new sdk.SpeakerAudioDestination();

      playerObj.onAudioEnd = () => {
        setPlayingStatus(0)
      }

      const audioConfig = sdk.AudioConfig.fromSpeakerOutput(playerObj);
      // Create the speech synthesizer.
      const synthes: sdk.SpeechSynthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
      setPlayer(playerObj)
      setSynthesizer(synthes)
    }
    catch(err) {
      console.error(err)
      return;
    }

    // eslint-disable-next-line consistent-return
    return () => {
      setPlayer(null)
      setSynthesisComplete(false)
    }
  }

  useEffect(initSDK, [key, voiceName, text]);

  const synthesize = useCallback(() => {
    if (synthesizer) {
      synthesizer.speakTextAsync(text, () => {
        setSynthesisComplete(true)
        synthesizer.close()
        setSynthesizer(null)
      })
    }
  }, [synthesizer, text])

  const handleSpeechPlay = useCallback((e: Event) => {
    e.preventDefault()
    if (playingStatus === 0) {
      // Play.
      setPlayingStatus(1)
      if (!synthesisComplete) {
        synthesize()
      }
      player?.pause()
      player?.resume()

      const eventName: Models.InteractivityStarted['eventName'] = 'Interactivity Started';
      trackEvent(eventName, {});
    }
    else if (playingStatus === 1) {
      // Pause.
      setPlayingStatus(2)
      player?.pause()
    }
    else if (playingStatus === 2) {
      // Resume.
      setPlayingStatus(1)
      player?.resume()
    }

  }, [player, playingStatus, synthesisComplete, synthesize])

  useEffect(() => {
    const playButton = playBtn.current

    playButton?.addEventListener('click', handleSpeechPlay)

    return () => {
      playButton?.removeEventListener('click', handleSpeechPlay)
    }
  });

  useEffect(() => {

    const pausePlayer = () => {
      setPlayingStatus(2)
      player?.pause()
    }

    const closePlayer = () => {
      setPlayingStatus(0)
      player?.close()
    }

    router.events.on('routeChangeStart', pausePlayer);
    router.events.on('routeChangeComplete', closePlayer);

    return () => {
      router.events.off('routeChangeStart', pausePlayer);
      router.events.off('routeChangeComplete', closePlayer);
    }
  }, [player, router.events]);

  const getPlayBtnLabel = useCallback(() => {
    if (playingStatus === 0) {
      return 'Artikel vorlesen'
    }
    if (playingStatus === 1) {
      return 'Vorlesen pausieren'
    }
    if (playingStatus === 2) {
     return 'weiter vorlesen'
    }
    return '';
  }, [playingStatus])

  return (
    <div className={styles.wrapper}>
      <a ref={playBtn} aria-label={getPlayBtnLabel()} className={classNames(styles.triggerSpeech)} href='#'>
        { playingStatus === 1 ? <SvgImage reference='pause' />
          : <SvgImage className={styles.play} reference='play' />
        }
        { getPlayBtnLabel() }
      </a>
    </div>
  )
}

export default TextToSpeech;
